
import{BoxContainer, Container, ContainerPage,Texto, BoxSubtitulo,Box,BoxC,BoxD, SectionA, SectionB, BoxAccordion,Titulo, BoxB, SectionD} from "./style";

import sol from '../../../assets/Img/iconsol.png';
import dinheiro from '../../../assets/Img/icondinheiro.png';
import calendar from '../../../assets/Img/iconcalendar.png';
import painel from '../../../assets/Img/iconPainel.png';
import contaantes from '../../../assets/Img/contaantes.png';
import contadepois from '../../../assets/Img/contadepois.png';
import associacao from '../../../assets/Img/boletoassociacao.png';
import resultados from '../../../assets/Img/resultados02.png';
import carteira from '../../../assets/Img/iconcarteira.png';
import { CardOrcamentoUsina } from "../../../Components/CardOrcamentoUsina";

import { BoxTitulo } from "../../../Components/BoxTitulo";
import { Footer } from "../../../Components/Footer";
import { CardUsina } from "../../../Components/CardUsina";
import BasicExample from "../../../Components/Acordion";
import { ImgAluguel } from "../../../Components/ImgAluguel";
import { ImgAluguelFat } from "../../../Components/ImgAluguelFat";
//import { Footer } from "../../../Components/Footer";



export const UsinaAluguel: React.FC = () => {

  return(
  
<Container>
   <SectionD>
      <BoxTitulo titulo="LOCAÇÃO DE USINA" traco="________________"/>
     <BoxContainer>
        <ContainerPage>
         
          <Box>
            <Texto>
            Alugue uma fração de uma usina solar fotovoltaica e economize todo mês
            sem investir absolutamente NADA.
            </Texto>

            <CardUsina imagem={carteira} descricao="Redução na conta de energia"/>
            <CardUsina imagem={painel} descricao="Sem instalação sem manutenção"/>
            <CardUsina imagem={dinheiro} descricao="Sem investimento ou taxas"/>
            <CardUsina imagem={calendar} descricao="Economia o ano todo"/>
            <CardUsina imagem={sol} descricao="Energia sustentável"/>
           </Box>
        </ContainerPage>
         <CardOrcamentoUsina/>
     </BoxContainer>
   </SectionD>
   
      <SectionB>
       
         <BoxSubtitulo>Exemplo real da economia com locação de usinas.</BoxSubtitulo>
         <BoxB>
         <BoxC>
         <ImgAluguel imagem={contaantes} texto="Conta da cemig sem energia solar"/>
         <ImgAluguel imagem={contadepois} texto="Conta da cemig com energia solar"/>
         </BoxC>
         <BoxD>
         <ImgAluguel imagem={associacao} texto="Boleto de locação de equipamentos fotovoltaícos."/>
         <ImgAluguelFat imagem={resultados} texto="Faturas"/>
         </BoxD>
         </BoxB>
         
       </SectionB>


     <SectionA>
      <Titulo>Dúvidas frequentes</Titulo>
      <BoxAccordion>
      <BasicExample/>
      </BoxAccordion>
     </SectionA>


        <Footer></Footer>
</Container>

);
}